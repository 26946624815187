import React, { useState } from "react"
import { Link as GatsbyLink } from "gatsby"
import {
  Box,
  Flex,
  Center,
  Container,
  Text,
  Heading,
  Link,
} from "@chakra-ui/react"
import { motion } from "framer-motion"

import Bear399Logo from "../StickyHeader/Bear399Logo"
import NewWestProperties from "./NewWestPropertiesLogo"

const activeStyles = {
  color: "#eab117",
}

const MotionBox = motion(Box)

const StickyNavMenu = () => {
  return (
    
      <Flex
        w="100%"
        justifyContent={"space-around"}
        alignItems={"center"}
        className="desktopNav"
        id="nav-links"
        display={{ sm: 'none', md: 'none', lg: 'flex', xl: 'flex'}}
        marginTop={{ lg: '-2rem', xl: '0'}}
      >
        <MotionBox
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          transition={{
            duration: 1,
            delay: 0.75,
          }}
          my="6"
          w="35%"
        >
           <Box display={{ md: 'none', lg: 'block'}}>              
            <NewWestProperties width="220px"/>
            </Box>
        </MotionBox>
        <MotionBox
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          transition={{
            duration: 1,
            delay: 1,
          }}
          my="6"
        >
          <Box>
            <Link
              //as={GatsbyLink}
              variant="nav-item"
              _hover={{ textDecoration: "none", color: "pine.800" }}
              href="/#book-our-properties"
              activeClassName="active"
              fontSize={{ lg: '.8rem', xl: '1rem'}}
            >
              Book Our Properties
            </Link>
          </Box>
        </MotionBox>
        <MotionBox
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          transition={{
            duration: 1,
            delay: 1.25,
          }}
          my="6"
        >
          <Box>
            <Link
              as={GatsbyLink}
              variant="nav-item"
              _hover={{ textDecoration: "none", color: "pine.800" }}
              to="/about-us"
              activeClassName="active"
              fontSize={{ lg: '.8rem', xl: '1rem'}}
            >
              About Us
            </Link>
          </Box>
        </MotionBox>
        <MotionBox
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          transition={{
            duration: 1,
            delay: 1.5,
          }}
          my="6"
        >
          <Box>
            <Link
              as={GatsbyLink}
              variant="nav-item"
              _hover={{ textDecoration: "none", color: "pine.800" }}
              to="/our-locations"
              activeClassName="active"
              fontSize={{ lg: '.8rem', xl: '1rem'}}
            >
              Our Locations
            </Link>
          </Box>
        </MotionBox>
      </Flex>
    
  )
}

export default StickyNavMenu
