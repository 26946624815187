import { Box, Heading, Flex, Container, Button } from "@chakra-ui/react"
import { Link } from 'gatsby'
import React, {useEffect} from "react"
import { motion } from "framer-motion"

import HomeNavMenu from "./NavMenu/HomeNavMenu"
import { FaChevronRight } from "react-icons/fa"

const MotionBox = motion(Box)

const Header = () => {
  // const {
  //   location,
  // } = props;

  // const currentPage = location.pathname;

  // useEffect(() => {
  //   //setKey(props.location.pathname.id);
  //   console.log(currentPage);
  // }, []);

  return (
    <header>
      {/* <Box w="100%">
      <Box bg="pine.400" p={2} w="100%" display={{ sm: 'none', md: 'none', lg: 'block' }} h="2rem"/>
      
      <Flex w="100%" justifyContent="flex-end" mr="3rem" display={{ sm: 'none', md: 'none', lg: 'flex'}}>
        <Box mr="4rem">
        <Link to="/donate">
      <Button as="h1" textAlign="center" fontSize="lg" bgColor="pine.400" color="white" borderRadius="0" fontFamily="Open Sans" _hover={{ backgroundColor: 'gold.400', color: 'gray.800'}} >
          Donate&nbsp;<em>Now</em>&nbsp;<FaChevronRight />
        </Button>
        </Link>
        </Box>
        </Flex>
        </Box> */}
      <Flex>
        <Container
          centerContent
          maxW="100%"
          justifyContent="center"
          h="100%"
          flexWrap="wrap"
          bg="rgba(101, 164, 156, 0.83)"   
        >
          <MotionBox
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            transition={{ duration: 0.5 }}
            my={0}
            py={0}
          >

          </MotionBox>
          
      
            {/* {location.pathname === '/' ? 
              <HomeNavMenu />
              : <NavMenu />
            } */}
          
        <HomeNavMenu />

          <MotionBox
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            transition={{ duration: 1.5 }}
          >
            {/* <Text as="div" my="6"  fontSize="2xl"
            style={{
              textAlign: 'center',
              textShadow: '2px 2px 2px black'
            }}
          >
            We're currently working on our new website. To get in touch, please fill out the form below:
          </Text>
            <ContactForm /> */}
          </MotionBox>
        </Container>
      </Flex>
    </header>
  )
}

export default Header
