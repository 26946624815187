import { extendTheme } from '@chakra-ui/react'
import { createBreakpoints } from '@chakra-ui/theme-tools'

const breakpoints = createBreakpoints({
    sm: "360px",
    md: "768px",
    lg: "1024px",
    xl: "1440px"
});

const theme = { 
    config: {
        initialColorMode: "light",
        useSystemColorMode: false,
    },
    breakpoints,
    fonts: {
        body: "Roboto Slab, serif",
        heading: "Roboto Slab, serif"
    },
    colors: {
        gold: {
            100: '#eab117',
            400: '#eab117'
        },
        pine: {
            '50':  '#f2f6f5',
            '100': '#dbeff1',
            '200': '#a7e7d7',
            '300': '#7ac6be',
            '400': '#3ca596',
            '500': '#2b8971',
            '600': '#257158',
            '700': '#215645',
            '800': '#183b33',
            '900': '#112525',
          },
},
    styles: {
        global: {
            a: {
                color: 'pine.400',
                _hover: {
                    textDecoration: 'none',
                    color: 'pine.700'
                }
            },
            p: {
                marginBottom: '1.5rem'
            }
        }
    },
    components: {
        Text: {
            marginBottom: '1.5rem'
        },
        Link: {
            variants: {
                "nav-item": {
                    color: "white",
                    fontFamily: "Roboto Slab",
                    fontSize: "1rem",
                    textTransform: "uppercase",
                    fontWeight: 700
                },
                "footerNav": {
                    color: 'white !important',
                    _hover: {
                        textDecoration: 'none',
                        color: '#3ca596 !important'
                    }
                }
            }
        }
    }

}

export default extendTheme(theme)