import React from "react"
import { Link as GatsbyLink } from "gatsby"
import {
  Box,
  Flex,
  Center,
  Container,
  Text,
  Heading,
  Link,
} from "@chakra-ui/react"
import Bear399Logo from "../StickyHeader/Bear399Logo"
import styled from "@emotion/styled"
import NewWestProperties from "./NewWestPropertiesLogo"

const StickyNavMenu = () => {
  return (
    <>    
      <Flex
        w="100%"
        justifyContent={"space-around"}
        alignItems={"center"}
        className="desktopNav"
        id="sticky-nav-links"
      >
         <Box display={{ sm: 'none', md: 'block'}} w="35%">              
            <NewWestProperties width="180px"/>
            </Box>
          <Box>
            <Link
              variant="nav-item"
              _hover={{ textDecoration: "none", color: "gray.700" }}
              href="/#book-our-properties"
              activeClassName="active"
              fontSize={{
                md: "0.85rem",
                lg: "1rem"
              }}
            >
              Book Our Properties
            </Link>
          </Box>
          <Box>
            <Link
              as={GatsbyLink}
              variant="nav-item"
              _hover={{ textDecoration: "none", color: "gray.700" }}
              to="/about-us"
              activeClassName="active"
              fontSize={{
                md: "0.85rem",
                lg: "1rem"
              }}
            >
              About Us
            </Link>
          </Box>
          <Box>
            <Link
              as={GatsbyLink}
              variant="nav-item"
              _hover={{ textDecoration: "none", color: "gray.700" }}
              to="/our-locations"
              activeClassName="active"
              fontSize={{
                md: "0.85rem",
                lg: "1rem"
              }}
            >
              Our Locations
            </Link>
          </Box>
      </Flex>
    </>
  )
}

export default StickyNavMenu
