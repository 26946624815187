import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'


const Logo = styled.svg `
  width: ${props => props.width ? props.width : '160px' };
  height: auto;
  .cls-1 {
        fill: #fff;
      }

`;

const NewWestProperties = (props) => {
    return (
  <Link to="/">

<Logo xmlns="http://www.w3.org/2000/svg" viewBox="0 0 653.30997 211.96008" {...props}>
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <path class="cls-1" d="M254.94,154.8h-5.69v9.84h-3.8V139.61h9.49c4.89,0,8.02,3.23,8.02,7.59S259.83,154.8,254.94,154.8Zm-.21-11.78h-5.48v8.33h5.48c2.64,0,4.43-1.51,4.43-4.15C259.16,144.57,257.37,143.02,254.73,143.02Z"/>
        <path class="cls-1" d="M292.45,164.64l-5.27-10.55h-4.75v10.55h-3.8V139.61h9.7c4.75,0,7.77,3.06,7.77,7.31a6.571,6.571,0,0,1-4.99,6.64l5.76,11.07h-4.42Zm-4.39-21.62h-5.63v7.84h5.63c2.53,0,4.25-1.44,4.25-3.9S290.59,143.02,288.06,143.02Z"/>
        <path class="cls-1" d="M327.43,162.18a9.04551,9.04551,0,0,1-6.61,2.67,8.9535,8.9535,0,0,1-6.57-2.67c-2.46-2.46-2.43-5.24-2.43-10.05,0-4.82-.04-7.59,2.43-10.05a8.9535,8.9535,0,0,1,6.57-2.67,9.04541,9.04541,0,0,1,6.61,2.67c2.46,2.46,2.39,5.24,2.39,10.05S329.89,159.72,327.43,162.18Zm-2.88-17.82a5.25105,5.25105,0,0,0-7.45,0c-1.23,1.34-1.48,2.74-1.48,7.77s.25,6.43,1.48,7.77a5.25105,5.25105,0,0,0,7.45,0c1.23-1.34,1.48-2.74,1.48-7.77C326.03,147.1,325.78,145.69,324.55,144.36Z"/>
        <path class="cls-1" d="M356.01,154.8h-5.69v9.84h-3.8V139.61h9.49c4.89,0,8.02,3.23,8.02,7.59S360.9,154.8,356.01,154.8Zm-.21-11.78h-5.48v8.33h5.48c2.64,0,4.43-1.51,4.43-4.15C360.23,144.57,358.44,143.02,355.8,143.02Z"/>
        <path class="cls-1" d="M379.71,164.64V139.61H395.7v3.41H383.5v7.31h10.4v3.37H383.5v7.52h12.2v3.41H379.71Z"/>
        <path class="cls-1" d="M425.79,164.64l-5.27-10.55h-4.75v10.55h-3.8V139.61h9.7c4.75,0,7.77,3.06,7.77,7.31a6.571,6.571,0,0,1-4.99,6.64l5.76,11.07h-4.42Zm-4.39-21.62h-5.63v7.84h5.63c2.53,0,4.25-1.44,4.25-3.9S423.93,143.02,421.4,143.02Z"/>
        <path class="cls-1" d="M454.55,143.02v21.62h-3.8V143.02h-6.89v-3.41h17.58v3.41Z"/>
        <path class="cls-1" d="M476.84,164.64V139.61h3.8v25.03Z"/>
        <path class="cls-1" d="M498.25,164.64V139.61h15.99v3.41h-12.2v7.31h10.4v3.37h-10.4v7.52h12.2v3.41H498.25Z"/>
        <path class="cls-1" d="M537.16,164.85c-3.76,0-6.47-.88-8.86-3.3l2.53-2.5a8.25388,8.25388,0,0,0,6.4,2.39c3.27,0,5.17-1.41,5.17-3.83a3.393,3.393,0,0,0-.98-2.6,4.30651,4.30651,0,0,0-2.74-1.05l-2.95-.42a8.50025,8.50025,0,0,1-4.74-2,6.22488,6.22488,0,0,1-1.83-4.78c0-4.36,3.16-7.35,8.37-7.35a10.40252,10.40252,0,0,1,7.77,2.85l-2.43,2.39a7.33965,7.33965,0,0,0-5.45-1.93c-2.95,0-4.57,1.69-4.57,3.9a2.9648,2.9648,0,0,0,.95,2.32,5.63081,5.63081,0,0,0,2.81,1.16l2.85.42a8.30157,8.30157,0,0,1,4.67,1.86,6.55947,6.55947,0,0,1,2.04,5.13C546.16,162.11,542.4,164.85,537.16,164.85Z"/>
      </g>
      <g>
        <path class="cls-1" d="M296.98,76.29l-3.48.96c-1.18.3-2.74,1.11-2.74,14.73v27.84h-9.85L257.07,86.13h-.74v14.36c0,13.47,1.26,14.36,2.44,14.59l4.37.96.22,3.26H245.44l.22-3.26,3.48-.96c1.11-.3,2.74-1.04,2.74-14.59V80.65c0-1.04-1.33-2.15-2.3-2.67l-3.26-1.7-.22-3.26h17.03l22.43,31.83h.74v-12.88c0-13.48-1.26-14.51-2.44-14.73l-4.37-.96-.22-3.26h17.92Z"/>
        <path class="cls-1" d="M343.62,119.3H299.86l.22-3.26,2.3-.29c1.78-.15,2.52-.52,2.52-1.41V77.99c0-.66-.37-1.18-3.04-1.41l-1.78-.3-.22-3.26h44.05V89.68h-6.51l-1.48-5.11c-2-7.26-4.59-7.85-14.36-7.85h-4.22V94.19h1.7c2.67,0,5.26-.44,6.22-3.11l1.85-5.11h4.81l-1.85,19.84h-4.59l-.89-4.66c-.37-2.44-1.56-3.18-5.85-3.18h-1.41v17.62h5.11c9.48,0,11.99-.89,14.51-7.92l1.48-4.07h6.66Z"/>
        <path class="cls-1" d="M424.75,76.43l-1.7.52c-4.07,1.26-5.7,2.67-9.33,14.07l-9.03,28.28h-10.44l-7.7-27.47h-.74l-9.03,27.47h-10.66l-12.51-38.57c-.74-2.3-1.55-3.33-3.85-3.77l-2.15-.52-.22-3.4h24.88l-.22,3.4-2.44.3c-2.37.3-2.96,2.59-1.33,8l6.74,24.8h.74l7.62-24.28-1.18-4.52a5.00235,5.00235,0,0,0-3.85-3.77l-2.15-.52-.22-3.4h24.88l-.22,3.4-2.44.3c-2.67.37-2.89,2.22-1.33,8l6.22,24.73h.74l5.11-18.43c2.74-9.85,2.07-13.25-1.48-14.22l-2.15-.37-.3-3.4h19.92Z"/>
        <path class="cls-1" d="M514.69,76.43l-1.7.52c-4.07,1.26-5.7,2.67-9.33,14.07l-9.03,28.28h-10.44l-7.7-27.47h-.74l-9.03,27.47h-10.66l-12.51-38.57c-.74-2.3-1.55-3.33-3.85-3.77l-2.15-.52-.22-3.4h24.88l-.22,3.4-2.44.3c-2.37.3-2.96,2.59-1.33,8l6.74,24.8h.74l7.62-24.28-1.18-4.52a5.00235,5.00235,0,0,0-3.85-3.77l-2.15-.52-.22-3.4H490.8l-.22,3.4-2.44.3c-2.67.37-2.89,2.22-1.33,8l6.22,24.73h.74l5.11-18.43c2.74-9.85,2.07-13.25-1.48-14.22l-2.15-.37-.3-3.4h19.92Z"/>
        <path class="cls-1" d="M557.7,119.3H513.94l.22-3.26,2.3-.29c1.78-.15,2.52-.52,2.52-1.41V77.99c0-.66-.37-1.18-3.04-1.41l-1.78-.3-.22-3.26h44.05V89.68h-6.51L550,84.57c-2-7.26-4.59-7.85-14.36-7.85h-4.22V94.19h1.7c2.67,0,5.26-.44,6.22-3.11l1.85-5.11H546l-1.85,19.84h-4.59l-.89-4.66c-.37-2.44-1.56-3.18-5.85-3.18h-1.41v17.62h5.11c9.48,0,11.99-.89,14.51-7.92l1.48-4.07h6.66Z"/>
        <path class="cls-1" d="M583.45,120.49a59.08611,59.08611,0,0,1-16.07-2.08l-.44-15.25h6.59l3.26,11.85a22.94379,22.94379,0,0,0,6.66.96c6.44,0,8.66-3.03,8.66-6.59,0-4.52-3.78-6.14-10.66-8.14-9.48-2.67-15.18-6-15.18-14.14,0-8.37,6.81-15.03,18.95-15.03a54.45782,54.45782,0,0,1,11.4,1.26l4-1.11V88.21h-6.14l-3.7-10.81a16.90876,16.90876,0,0,0-5.85-.82c-5.55,0-8.66,2.52-8.66,5.85,0,3.85,3.33,5.4,9.48,7.18,11.18,3.18,16.88,6.74,16.88,15.18C602.63,113.45,595.81,120.49,583.45,120.49Z"/>
        <path class="cls-1" d="M647.04,89.61l-1.41-5.18c-2-7.26-5.11-7.77-8.59-7.77h-.67v37.76c0,.74.44,1.19,2.81,1.33l2.81.29.22,3.26H618.07l.22-3.26,3.11-.29c1.78-.15,2.52-.52,2.52-1.41V76.66h-.44c-3.85,0-6.81,1.04-8.81,8l-1.41,4.96h-6.89l1.55-16.58h45.39V89.62H647.04Z"/>
      </g>
      <g>
        <path class="cls-1" d="M105.99,0A105.9863,105.9863,0,0,0,62.41,202.6q5.445-9.09,10.85-18.22c0,.01.01.01.01.02q2.775-4.68,5.55-9.36,8.64-14.565,17.28-29.12,2.625-4.41,5.21-8.85c0-.01.01-.01.01-.02,7.75-13.24,15.48-26.59,24.24-39.14,4.02-5.76,11.12-5.48,15.19,0q5.145,6.93,10.21,13.91h0q4.86,6.675,9.66,13.38c.9,1.25,1.78,2.51,2.68,3.76q3.39,4.755,6.76,9.53c1.26,1.78,2.51,3.57,3.76,5.35q8.775,12.495,17.42,25.08A105.96356,105.96356,0,0,0,105.99,0ZM154.2,100.08c-1.65-2.2-3.3-4.4-4.93-6.61-3.81-5.14-8.46-8.71-15.19-8.71-6.6,0-11.54,3.51-15.19,8.71-9.27,13.22-18.06,26.77-26.75,40.39a43.73566,43.73566,0,1,1,62.06-33.78Z"/>
        <path class="cls-1" d="M163.98,160.34c-.85-1.23-1.72-2.44-2.58-3.66q-4.26-6.09-8.56-12.15-4.11-5.805-8.27-11.59c-1.46-2.04-2.91-4.08-4.38-6.11-.01.01-.01.01-.02.01-2.19-3.04-4.36-6.09-6.57-9.12-2.29,3.68-4.51,7.4-6.76,11.09-12.51,20.63-24.65,41.5-36.92,62.3h0q-5.175,8.775-10.4,17.52a106.08915,106.08915,0,0,0,99.74-26.13q-7.62-11.115-15.33-22.16Z"/>
      </g>
    </g>
  </g>
</Logo>
</Link>
    )
}

export default NewWestProperties
