import React from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    useDisclosure,
} from "@chakra-ui/react"
import { FaEnvelope } from "react-icons/fa"
import ContactForm from './ContactForm';

const ContactModal = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    
    return (
        <>
            <Button onClick={onOpen} color="white" fontSize="1.375rem" _hover={{ color: "pine.400"}} variant="ghost">
                Message Us <span style={{marginLeft: '10px'}}><FaEnvelope className="footer-icon" /></span>
            </Button>

            <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
        <ModalOverlay />
        <ModalContent>
          {/* <ModalHeader style={{zIndex: 'unset'}}>Send Us A Message</ModalHeader> */}
          <ModalCloseButton />
          <ModalBody>
            <ContactForm />
          </ModalBody>
        </ModalContent>
      </Modal>
        </>
    )
}

export default ContactModal;