import React, { useState } from "react"
import {
  Box,
  Grid,
  Text,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  Textarea,
} from "@chakra-ui/react"

import styled from '@emotion/styled'

import { useForm } from "react-hook-form"

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isSubmitting }
  } = useForm({
      mode: 'onSubmit',
      reValidateMode: 'onBlur'
  })

  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState(false);
  
  const onSubmit = data => {
    console.log(data);
    setProcessing(true);

    fetch('https://98fc3qygvf.execute-api.us-west-2.amazonaws.com/production/email-new-west-properties', {
          method: 'POST',
          headers: {
            "Content-Type": "application/json"
        },            
            body: JSON.stringify(data)
    })
    .then((response) => {
        if (response.status === 200) {
            console.log(JSON.stringify(data));
            setSucceeded(true);
            reset();
        } else {
            console.log('network error')
        }
    })

  }

  const ContactGrid = styled(Grid)`
    grid-template-columns: 1fr;

    @media(min-width: 48em) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-gap: 1rem;
    }
    
  `;

  const SuccessMessage = () => (
      <Box>      
          <Heading as="h4" w="100%" style={{textAlign: 'center'}}>Thank you for your message.<br />
          We'll be in touch soon!</Heading>
      </Box>
  )


  return (
    <>
      <Box  px={4} mb={8}>
      { succeeded ? <SuccessMessage /> : (
        <>
        <Heading as="h4" fontSize="xl" my="6">Send us a message</Heading>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ContactGrid className="contactGrid"  py={4}>
            <Box w="100%">
              <FormControl id="name" mb={{base: '4', md: '1'}}>
                <FormLabel>Your Name<Text color="red.200" as="span">*</Text></FormLabel>
                <Input type="text"
                    {...register("name", {required: true} )}
                />
                {errors.name && <Text color="red.200">Please enter your name.</Text>}
              </FormControl>
            </Box>
            {/* <Box w="100%">
              <FormControl id="email">
                <FormLabel>Company Name</FormLabel>
                <Input type="text" 
                    {...register("companyName")}
                 />
              </FormControl>
            </Box> */}
            <Box w="100%">
              <FormControl id="phone" mb={{base: '4', md: '1'}}>
                <FormLabel>Phone Number</FormLabel>
                <Input type="tel" 
                    {...register("phone")}
                />
              </FormControl>
            </Box>
            <Box w="100%">
              <FormControl id="email" mb={{base: '4', md: '1'}}>
                <FormLabel>Email address<Text color="red.200" as="span">*</Text></FormLabel>
                <Input type="email" 
                   {...register("email", {required: true} )}
                   />
                   {errors.email && <Text color="red.200">Please enter your email.</Text>}
              </FormControl>
            </Box>
          </ContactGrid>
          <Box>
        <FormControl id="message" mt={4}>
          <FormLabel>Your Message<Text color="red.200" as="span">*</Text></FormLabel>
            <Textarea placeholder=""
                {...register("message", {required: true} )}
                />
                {errors.message && <Text color="red.200">Please enter a message.</Text>}
            </FormControl>
            </Box>

          <Button type="submit" mt={4} colorScheme="pine" >
              {processing ? "Submitting..." : "Submit" }
          </Button>
        </form>
        </>
      )
}
      </Box>
    </>
  )
}

export default ContactForm
