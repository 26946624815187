import { Box, Link, Flex } from '@chakra-ui/react'
import { Link as GatsbyLink } from 'gatsby'
import React from 'react'
import { motion } from 'framer-motion'

import styled from "@emotion/styled"

import StickyNavMenu from '../NavMenu/StickyNavMenu'
// import SustainableBuildersColorLogo from '../HomePage/SustainableBuildersColorLogo'

const MotionBox = motion(Box);

const StickyHeaderContainer = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

const LargeStickyHeader = styled.div`
    @media (max-width: 480px) {
      display: none;
  }
`;

const StickyHeader = ({ sticky }) => {

  return (
    <Box>
    <StickyHeaderContainer>
    { sticky && (
      <LargeStickyHeader as="header" className="sticky-header" >
      <Flex as="div" m="0 auto" p="0.5rem 0.5rem" bg="rgba(101, 164, 156, 0.83)">
           <Link as={GatsbyLink}
             to="/"
             color="white"
             _hover={{ textDecoration: 'none'}}
           >
                       <MotionBox
               initial={{
                 opacity: 0,
               }}
               animate={{ 
                 opacity: 1
               }}
               transition={{ duration: 0.5}}
               p={2}
             >
             </MotionBox>
           </Link>
           <StickyNavMenu />
       </Flex>
       </LargeStickyHeader>
    )}
    </StickyHeaderContainer>
    </Box>
  )
}

export default StickyHeader
