import React, { useState } from "react"
import { Box, Link, Flex, Text, CloseButton, IconButton } from "@chakra-ui/react"
import { HamburgerIcon } from "@chakra-ui/icons"
import { Link as GatsbyLink } from "gatsby"
import { motion } from "framer-motion"

import styled from "@emotion/styled"
import Bear399Logo from "../StickyHeader/Bear399Logo"
import NewWestProperties from "./NewWestPropertiesLogo"

const MobileHeader = styled.header`
  display: block;
  @media (min-width: 768px) {
    display: none;
  }
`

const MotionBox = motion(Box)

const MobileMenu = (props) => {
  const [show, setShow] = useState(false)
  const handleToggle = () => (
      setShow(!show)
  )

  return (
  <Box display={{ sm: 'block', md: 'block', lg: 'none' }} position="fixed" top="0" className="mobile-header">
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      paddingLeft=".5rem"
      paddingRight="0.5rem"
      bg="rgba(101, 164, 156, 0.83)"  
      color="white"
      {...props}
      display="flex"
    >
      
      <Flex   w="75%">
        <Link
          as={GatsbyLink}
          to="/"
          color="white"
          _hover={{ textDecor: "none" }}
        >
          <MotionBox
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            transition={{ duration: 0.5 }}
            p={2}
          >
            
            <NewWestProperties width="200px"/>
          
          </MotionBox>
        </Link>
      </Flex>
    
    <div>
      <Box onClick={handleToggle}>
          { show ? <CloseButton /> : 
         ( <IconButton
            aria-label="Menu"
            icon={<HamburgerIcon />}
            variant="outline"
            size="sm"
        />)
  }      </Box>
</div>    
    <Box
        display={{ sm: show ? "block" : "none"}}
        width={{ sm: "full", md: "auto" }}
        alignItems="center"
        flexGrow={1}
        paddingBottom="1rem"
    >
        <Text mt={{ base: 4, md: 0 }} mr={6} display="block" textAlign="center">
        <Link href="/#book-our-properties" onClick={handleToggle} variant="nav-item" activeClassName="active">
            Book Our Properties
        </Link>
        </Text>
        <Text mt={{ base: 4, md: 0 }} mr={6} display="block" textAlign="center">
        <Link to="/about-us" as={GatsbyLink} onClick={handleToggle} variant="nav-item" activeClassName="active">
            About Us
        </Link>
        </Text>
        <Text mt={{ base: 4, md: 0 }} mr={6} display="block" textAlign="center">
        <Link to="/our-locations" as={GatsbyLink} onClick={handleToggle} variant="nav-item" activeClassName="active">
            Our Locations
        </Link>
        </Text>
   </Box>
    </Flex>
    </Box>
  )
}

export default MobileMenu