import React from 'react'
import { Box,
        Link,
        Flex,
        Text,
        Container } from "@chakra-ui/react"

import { Link as GatsbyLink } from "gatsby"       

import NewWestPropertiesLogoVert from './NewWestPropertiesLogoVert'
import ContactModal from './ContactModal'


const Footer = () => {

    return (
        <Box as="footer" overflowX="hidden">
        <Box w="100%" bg="#323232"  py={15} style={{
        }}>
            <Container
          maxW="container.xl"
          h="auto"
          py={5}>
              {/* <Grid templateColumns={{
            base: '100%',
            md: '1fr 2fr 1fr'
        }}
        alignItems="center"
        justifyContent="space-around"
        gap={4}
        > */}
        <Flex 
            flexWrap="wrap"
            flexDirection="row"
            w="100%"
            alignItems="center"
        >        
                <Box justifySelf={{
                    base: 'center',
                    md: 'left'
                }}
                color="white"
                fontFamily="Roboto Slab"
                fontSize="1rem"
                fontWeight="600"
                lineHeight="2.25rem"
                color="pine.400"
                textTransform="uppercase"
                w={{
                    base: '100%',
                    xl: '25%'
                }}
                textAlign={{
                    base: 'center',
                    xl: 'left',
                }}
                >
                    <Text mb="0">
                        Need to get in touch?

                    </Text>
                    <ContactModal />
                    {/* <Link as={GatsbyLink} color="white" fontSize="1.375rem" _hover={{ color: "pine.400"}}>
                    Message Us <FaEnvelope className="footer-icon" />
                    </Link> */}
                </Box>
                <Box w={{
                    base: '100%',
                    xl: '50%'
                }}
                my={{
                    base: '6',
                    xl: '0'
                }}
                >

                        <Flex justifyContent="center" w="100%" flexDirection="column" alignItems="center">
                            
                            <NewWestPropertiesLogoVert width="180px" />
                            <Box justifyContent="center" fontSize={[ "sm", "md" ]} color="white" fontWeight="600" flexWrap="wrap" textAlign="center" w="100%" mt="4">
        <Box w={{ sm: '100%' }}>
            &copy; New West Properties, {new Date().getFullYear()}
        </Box>
    
    </Box>
                        </Flex>
                        
                    
                </Box>
                <Box w={{
                    base: '100%',
                    xl: '25%'
                }}
                mb={{
                    lg: '0', 
                    xl: '1rem'
                }}
                textAlign={{
                    base: 'center',
                    xl: 'left',
                }}
                >
                   
                        <Box fontSize="1.5rem" fontWeight="600" >
                            <Link as={GatsbyLink} variant="footerNav" to="/#book-our-properties">
                                Book Our Properties
                            </Link>
                         </Box>
                         <Box fontSize="1.5rem" fontWeight="600" >
                            <Link as={GatsbyLink} variant="footerNav" to="/about-us">
                                About Us
                            </Link>
                         </Box>
                         <Box fontSize="1.5rem" fontWeight="600" >
                            <Link as={GatsbyLink}  variant="footerNav" to="/our-locations">
                                Our Locations
                            </Link>
                         </Box>
                    
                </Box>
                
              {/* </Grid> */}
              </Flex>
            </Container>
            <Box>
                
            </Box>
        </Box>
    </Box>
    )
}

export default Footer