/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

 import { wrapPageElement as wrap } from './src/root-wrapper'

 export const wrapPageElement = wrap

